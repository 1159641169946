* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }

  .profile-card {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .cover-photo {
    height: 150px;
    background: url('https://source.unsplash.com/random/800x150?office') no-repeat center/cover;
  }
  
  .profile-info {
    padding: 20px;
    position: relative;
  }
  
  .profile-img {
    width: 20vh;
    height: 20vh;
    border-radius: 50%;
    border: 4px solid white;
    position: absolute;
    top: -12vh;
    left: 20px;
    object-fit: cover;
    background: white;
  }
  
  .profile-details {
    margin-left: 150px;
  }
  
  .profile-details h1 {
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
  
  .profile-details h3 {
    color: gray;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .about {
    margin-top: 15px;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  .contact-info {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    color: #0073b1;
    font-size: 0.9rem;
  }
  
  .social-links {
    margin-top: 15px;
  }
  
  .social-links a {
    text-decoration: none;
    margin-right: 10px;
    color: #0073b1;
    font-weight: bold;
  }
  
  @media (max-width: 600px) {
    .profile-details {
      margin-left: 0;
      margin-top: 70px;
    }
  
    .profile-img {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  